@import '/Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';

#wrongMarket {
  .modal-dialog {
    width: calc(100vw - (@spacer6 * 2));
    max-width: 390px;
    position: absolute;
    bottom: @spacer6;
    right: @spacer6;
    margin: 0 auto;

    .modal-content {
      border-radius: 0;

      .modal-header {
        border: none;
        display: flex;
        justify-content: flex-end;

        .close-btn {
          min-height: 0;
          min-width: 24px;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            height: 12px;
            width: 12px;
          }
        }
      }

      #wrongMarketSelectButton {
        min-height: 0;
        min-width: 0;
      }
    }
  }
}
